import React from 'react'
import { SimpleGrid, Tabs, TabList, Tab, TabPanel, TabPanels, Flex } from '@chakra-ui/react'
import { BarChart, IBarChartProps, IData } from './Charts/BarChart'
import { useAnalytics } from '../../hooks/useAnalytics'
import { AnalyticType } from '../../ts/interfaces/analytics'
import { Stats } from './Stats'
import { DateRangePicker } from '../fields/Date/DateRangePicker'
import moment from 'moment'
import { formatCurrencyAmount } from '../../utils/ui'
import { SelectField } from '../fields'
import { ExpensesFilterBy, ExpensesFilterByOptions } from '../../ts/interfaces/expenses'
import { useSize } from '@chakra-ui/react-use-size'
import { useUrlParamFilter } from '../../hooks/useDateUrlParamFilter'

const Analytics = React.memo(() => {
  const {
    dateValue: dateRangeValue,
    setDateValue: setDateRangeValue,
    filterBy,
    setFilterBy
  } = useUrlParamFilter({
    useFilterBy: true,
    initialDateRange: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
  })
  const [tabIndex, setTabIndex] = React.useState<number>(0)

  const elementRef1 = React.useRef<HTMLDivElement>(null)
  const dimensions1 = useSize(elementRef1)

  const elementRef2 = React.useRef<HTMLDivElement>(null)
  const dimensions2 = useSize(elementRef2)

  return (
    <>
      <Flex
        alignItems='end'
        columnGap='1rem'
        flexWrap='wrap'
        justifyContent={{
          base: 'center',
          md: 'start'
        }}
      >
        <DateRangePicker
          label='Rango de fecha:'
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />

        <SelectField
          isSearchable={false}
          isClearable={false}
          options={ExpensesFilterByOptions}
          value={filterBy}
          onChange={(_, value) => {
            setFilterBy(value as ExpensesFilterBy)
          }}
          label='Filtrar por fecha de:'
        />
      </Flex>
      <Stats
        filterBy={filterBy}
        dateRange={dateRangeValue}
        mb='2rem'
      />

      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
      >
        <TabList>
          <Tab>Tickets</Tab>
          <Tab>Gastos</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SimpleGrid
              placeItems='end'
              columns={{
                base: 1,
                lg: 2
              }}
              columnGap={{
                base: '2rem'
              }}
              rowGap={{
                base: '2rem'
              }}
              ref={elementRef1}
            >
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por usuario'
                serieTitle='Cantidad de tickets'
                type={AnalyticType.QUANTITY_RECEIPTS_BY_USER}
                dateRange={dateRangeValue}
                filterBy={filterBy}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por categoría'
                serieTitle='Cantidad de tickets'
                type={AnalyticType.QUANTITY_RECEIPTS_BY_CATEGORY}
                dateRange={dateRangeValue}
                filterBy={filterBy}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por día'
                serieTitle='Cantidad de tickets'
                dateRange={dateRangeValue}
                type={AnalyticType.QUANTITY_RECEIPTS_BY_DATE}
                filterBy={filterBy}
                dataMapper={({ label, value }) => ({ label: moment(label).format('DD-MMM-YY'), value: value })}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por forma de pago '
                serieTitle='Cantidad de tickets'
                type={AnalyticType.QUANTITY_RECEIPTS_BY_PAYMENT_METHOD}
                dateRange={dateRangeValue}
                filterBy={filterBy}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid
              columns={{
                base: 1,
                lg: 2
              }}
              columnGap={{
                base: '2rem'
              }}
              rowGap={{
                base: '2rem'
              }}
              ref={elementRef2}
            >
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por usuario'
                serieTitle='Total de gasto'
                type={AnalyticType.TOTAL_EXPENSES_BY_USER}
                dateRange={dateRangeValue}
                filterBy={filterBy}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por categoría'
                serieTitle='Total de gasto'
                type={AnalyticType.TOTAL_EXPENSES_BY_CATEGORY}
                dateRange={dateRangeValue}
                filterBy={filterBy}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por día'
                serieTitle='Total de gasto'
                dataMapper={({ label, value }) => ({ label: moment(label).format('DD-MMM-YY'), value: value })}
                type={AnalyticType.TOTAL_EXPENSES_BY_DATE}
                dateRange={dateRangeValue}
                filterBy={filterBy}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por forma de pago'
                serieTitle='Total de gasto'
                type={AnalyticType.TOTAL_EXPENSES_BY_PAYMENT_METHOD}
                dateRange={dateRangeValue}
                filterBy={filterBy}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
})

export { Analytics }

import React from 'react'
import { IconButton, Box, Flex, useBoolean, Button, Drawer, DrawerContent, DrawerOverlay, useDisclosure, useColorModeValue, Icon, FlexProps, BoxProps, Link as ChakraLink, useToast } from '@chakra-ui/react'
import { ConfirmDialog } from '../fragments/ConfirmDialog'
import { FaDoorOpen, FaQuestion } from 'react-icons/fa'
import { FiMenu } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { logOut } from '../../store/user/slice'
import { useAppSelector } from '../../store/hooks'
import { routerObj } from '../routing/Router'
import { colors } from '../../utils/colors'
import { rounded } from '../../utils/ui'
import { MdBook, MdGroup, MdHome, MdMoney, MdPerson2, MdStickyNote2 } from 'react-icons/md'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
//@ts-ignore
import ZummaBannerLogo from '../../assets/svg/ZummaBannerLogo.svg'
import { clearInvoicesState } from '../../store/invoices/slice'
import { setError } from '../../store/ui/slice'
import { SendInvoiceButton } from '../ui/SendInvoiceButton'
import { ReferralDialog } from '../ui/ReferralDialog'

const NavItem = React.memo(
  (
    props: FlexProps & {
      icon: any
      path: string
      sidebar: {
        isOpen: boolean
        onOpen: () => void
        onClose: () => void
        onToggle: () => void
        isControlled: boolean
        getButtonProps: (props?: any) => any
        getDisclosureProps: (props?: any) => any
      }
    }
  ) => {
    const { icon, children, path, ...rest } = props
    const navigate = useNavigate()

    return (
      <Link
        id={`nav-link-${path}`}
        to={path}
        onClick={(e) => {
          e.preventDefault()
          navigate(path)
          props.sidebar.onClose()
        }}
      >
        <Flex
          maxW='100%'
          rounded={rounded.container}
          align='center'
          px='4'
          pl='4'
          py='3'
          cursor='pointer'
          color='inherit'
          _dark={{
            color: 'gray.400'
          }}
          _hover={{
            bg: 'gray.100',
            _dark: {
              bg: 'gray.900'
            },
            color: 'gray.900'
          }}
          bg={path === location.pathname ? colors.tertiary[3] : 'initial'}
          role='group'
          fontWeight='semibold'
          transition='.15s ease'
          {...rest}
        >
          {icon && (
            <Icon
              mx='2'
              boxSize='4'
              _groupHover={{
                color: 'gray.600'
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    )
  }
)

const SidebarContent = React.memo(
  ({
    sidebar,
    setShowLogOut,
    ...props
  }: BoxProps & {
    sidebar: {
      isOpen: boolean
      onOpen: () => void
      onClose: () => void
      onToggle: () => void
      isControlled: boolean
      getButtonProps: (props?: any) => any
      getDisclosureProps: (props?: any) => any
    }
    setShowLogOut: {
      on: () => void
      off: () => void
      toggle: () => void
    }
  }) => (
    <Flex
      px='4'
      flexDir={{
        base: 'column'
      }}
      justifyContent={{
        base: 'start'
      }}
      as='nav'
      pos='fixed'
      top='0'
      left='0'
      h='100%'
      pb='10'
      overflowX='hidden'
      overflowY='auto'
      _dark={{
        bg: 'gray.800'
      }}
      color='inherit'
      borderRightWidth='1px'
      w='60'
      {...props}
    >
      <Flex
        px='4'
        py='5'
        align='center'
      >
        {/* <Logo /> */}

        <Link to={routerObj.home.path}>
          <ReactSVG src={ZummaBannerLogo} />
        </Link>
      </Flex>
      <Flex
        direction='column'
        as='nav'
        fontSize='sm'
        color='gray.600'
        aria-label='Main Navigation'
        rowGap='0.2rem'
      >
        <NavItem
          path={routerObj.home.path}
          icon={MdHome}
          sidebar={sidebar}
        >
          Home
        </NavItem>
        <NavItem
          path={routerObj.profile.path}
          icon={MdPerson2}
          sidebar={sidebar}
        >
          Perfil
        </NavItem>
        <NavItem
          path={routerObj.pricing.path}
          icon={MdMoney}
          sidebar={sidebar}
        >
          Precios
        </NavItem>
        <NavItem
          path={routerObj.usersDashboard.path}
          icon={MdGroup}
          sidebar={sidebar}
        >
          Usuarios
        </NavItem>
        <NavItem
          path={routerObj.taxInformationDashboard.path}
          icon={MdBook}
          sidebar={sidebar}
        >
          Razones sociales
        </NavItem>
        <NavItem
          path={routerObj.expensesDashboard.path}
          icon={MdStickyNote2}
          sidebar={sidebar}
        >
          Gastos
        </NavItem>
      </Flex>

      <Flex
        marginTop='auto'
        flexDir='column'
        gap='1rem'
      >
        <SendInvoiceButton label='Ir a WhatsApp' />
        <ReferralDialog />
        <Button
          justifySelf='end'
          id='logOutButton'
          onClick={setShowLogOut.toggle}
          gap={2}
        >
          Cerrar sesión
          <FaDoorOpen />
        </Button>

        <Button
          justifySelf='end'
          id='needHelpButton'
          onClick={() => {
            window.open(process.env.REACT_APP_HELP_URL, '_blank')
          }}
          gap={2}
        >
          Necesitas ayuda{' '}
          <Icon
            color='gray.500'
            as={FaQuestion}
            cursor='pointer'
          />
        </Button>
      </Flex>
    </Flex>
  )
)

const Layout = React.memo(() => {
  const token = useAppSelector((state) => state.user.token)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  const ui = useAppSelector((state) => state.ui)

  const [showLogOut, setShowLogOut] = useBoolean(false)

  const sidebar = useDisclosure()
  const safeRoutes = React.useMemo(() => [routerObj.signUp.path, routerObj.signIn.path, routerObj.recoverPassword.path, routerObj.renewPassword.cleanParamsPath], [routerObj])

  React.useEffect(() => {
    if (ui.errorMessage) {
      toast({
        title: `Hubo un error ${ui.errorStatus ? ui.errorStatus : ''}`,
        description: ui.errorMessage,
        status: 'error',
        duration: 8000,
        isClosable: true
      })
      dispatch(setError(null))
    }
  }, [ui.errorMessage, ui.errorStatus, ui.isFetching])

  React.useEffect(() => {
    if (!token && !safeRoutes.includes(location.pathname)) {
      navigate(routerObj.signIn.path)
    } else if (token && ['/sign-up', '/sign-in', '/'].includes(location.pathname)) {
      navigate(routerObj.home.path)
    }
  }, [token, location, routerObj])

  React.useEffect(() => {
    if (![routerObj.expensesDashboard.path].includes(location.pathname)) {
      window.scrollTo(0, 0)
    }
  }, [location])

  const forbiddenPaths = React.useMemo(() => [routerObj.renewPassword.cleanParamsPath, routerObj.welcomeSubscriptionPage.path], [routerObj])

  const handleLogOut = React.useCallback(() => {
    setShowLogOut.off()
    sidebar.onClose()
    dispatch(logOut())
    dispatch(clearInvoicesState())
    navigate(routerObj.signIn.path)
  }, [navigate, setShowLogOut, sidebar, dispatch, clearInvoicesState, logOut])

  // return <Outlet />

  if (!token || forbiddenPaths.find((path) => location.pathname.match(path)))
    return (
      <Box
        bg={colors.neutro[3]}
        as='main'
        maxW='100%'
        height='100vh'
        p='1rem'
      >
        <Outlet />
      </Box>
    )

  return (
    <Box
      as='section'
      bg='gray.50'
      _dark={{
        bg: 'gray.700'
      }}
      minH='100vh'
      position='relative'
      zIndex='sticky'
      width='100%'
    >
      <SidebarContent
        setShowLogOut={setShowLogOut}
        display={{
          base: 'none',
          lg: 'flex'
        }}
        sidebar={sidebar}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement='left'
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            setShowLogOut={setShowLogOut}
            sidebar={sidebar}
            w='full'
            borderRight='none'
          />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          lg: 60
        }}
        transition='.3s ease'
      >
        {/* search bard */}
        <Flex
          id='LayoutHeader'
          as='header'
          alignItems='center'
          justify='end'
          w='full'
          px='4'
          zIndex='sticky'
          position='sticky'
          top={0}
          bg={colors.white}
          borderBottomWidth='1px'
          color='inherit'
          h='14'
          gap={{
            base: '1rem'
          }}
          flexDir={{
            base: 'row-reverse',
            lg: 'row'
          }}
          style={{
            zIndex: 9999
          }}
        >
          <IconButton
            aria-label='Menu'
            display={{
              base: 'inline-flex',
              lg: 'none'
            }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size='sm'
          />

          <SendInvoiceButton />
        </Flex>

        {/* CHILDREN */}
        <Box
          bg={colors.neutro[3]}
          as='main'
          minW='100%'
          maxW='fit-content'
          minH='100vh'
          maxH='100%'
        >
          <Outlet />
          {ui.isFetching ? (
            <div className='spinner'>
              <div></div>
            </div>
          ) : null}
        </Box>
      </Box>

      <ConfirmDialog
        confirmMethod={handleLogOut}
        isOpen={showLogOut}
        title='¿Desea cerrar sesión?'
        onClose={setShowLogOut.off}
      />
    </Box>
  )
})

export { Layout }

import moment from 'moment'
import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { GetAnalyticsBody, GetAnalyticsResponse, GetStatsResponse } from '../ts/interfaces/request'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { getEndDateString, getStartDateString } from '../utils/date'

//NOTE: the app must always send UTC for filter dates

export const AnalyticsService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props),

  async getAnalytics({ type, start_date, end_date, filter_by, signal }: GetAnalyticsBody & { signal?: AbortSignal }): Promise<GetAnalyticsResponse['data'] | null> {
    try {
      const urlParams = new URLSearchParams({
        type,
        start_date: getStartDateString(start_date),
        end_date: getEndDateString(end_date),
        filter_by: filter_by
      })

      const response = await this.requestHandler.get<GetAnalyticsResponse>(`analytics/?${urlParams}`, {
        signal
      })

      return response.data.data
    } catch (error) {
      if (signal?.aborted) {
        return null
      } else {
        throw error
      }
    }
  },
  async getStats({ start_date, end_date, filter_by, signal }: { start_date: Date; end_date: Date; filter_by: ExpensesFilterBy; signal?: AbortSignal }) {
    try {
      const params = new URLSearchParams({
        start_date: getStartDateString(start_date),
        end_date: getEndDateString(end_date),
        filter_by
      })

      const response = await this.requestHandler.get<GetStatsResponse>(`analytics/stats?${params}`, { signal })
      return response.data.data
    } catch (error) {
      if (signal?.aborted) {
        throw new DOMException('Aborted', 'AbortError')
      }
      throw error
    }
  }
})

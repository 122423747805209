import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, Flex, useToast, Center } from '@chakra-ui/react'
import { FaAward } from 'react-icons/fa'
import { colors } from '../../utils/colors'
import { CopyIcon } from '@chakra-ui/icons'
import { ImAmazon } from 'react-icons/im'

const ReferralDialog = React.memo(() => {
  const referralsDisclosure = useDisclosure()
  const toast = useToast()

  const handleCopyContent = () => {
    navigator.clipboard.writeText('Zumma me está ahorrando muchas horas y miles de pesos en impuestos ya que me ayuda a facturar todos los gastos de mi empresa. Deberías de probarlo, agenda un demo y menciona mi nombre para te den 30% de descuento en tu primer mes: https://www.zummafinancial.com/registro-demo')
    toast({
      title: 'Texto copiado al portapapeles',
      description: '¡Has copiado el texto para recomendar Zumma!',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  }

  return (
    <>
      <Button
        justifySelf='end'
        id='referralDialogButton'
        onClick={referralsDisclosure.onOpen}
        gap={2}
        bg={colors.tertiary[2]}
      >
        Recomienda Zumma
        <FaAward />
      </Button>
      <Modal
        trapFocus={false}
        closeOnOverlayClick={true}
        isOpen={referralsDisclosure.isOpen}
        onClose={referralsDisclosure.onClose}
        isCentered
        size={{
          base: '100%',
          md: '50%'
        }}
      >
        <ModalOverlay />

        <ModalContent
          maxW={{
            base: '100%',
            md: '50%'
          }}
          px={{
            base: 0,
            md: '1rem'
          }}
        >
          <ModalHeader
            px={{
              base: '2rem',
              md: '4rem'
            }}
            textAlign='center'
          >
            Recomienda Zumma y gana $1,000 pesos en una tarjeta de Amazon
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Flex
              flexDir='column'
              gap='1rem'
            >
              <p>Zumma quiere recompensarte por recomendar a tus amigos o compañeros de trabajo que necesiten eficientar la facturación de sus gastos.</p>
              <p>
                Comparte el siguiente mensaje y recibe <b>$1,000</b> pesos en una tarjeta de regalo de Amazon por cada amigo que utilice tu nombre y/o correo para contratar su Plan de Facturación.
              </p>
              <p>Para recomendar Zumma, solo copia el texto del botón de abajo y envíaselo a tus amigos o compañeros por email o WhatsApp.</p>
            </Flex>

            <Center mt='1rem'>
              <Flex
                _hover={{
                  shadow: 'lg'
                }}
                rounded='md'
                px='4rem'
                py='1.5rem'
                backgroundColor={colors.black}
                align='center'
                maxW='fit-content'
                justifyContent='center' // Center the SVG within the Flex container
                cursor='pointer'
                onClick={handleCopyContent}
                id='referralDialogAmazonBanner'
              >
                <ImAmazon
                  size='4rem'
                  color={colors.white}
                />
              </Flex>
            </Center>
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button
              onClick={handleCopyContent}
              variant='ghost'
              gap={2}
              bg={colors.tertiary[3]}
              id='referralDialogCopyContentButton'
              flexWrap='wrap'
            >
              Copiar texto para recomendar Zumma
              <CopyIcon />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})

export { ReferralDialog }

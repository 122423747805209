import { TaxInformation, ZReceipt, InvoiceStatus } from './invoices'
import { User } from './interfaces'

export const CategoryOptions = [
  { value: 'oficina', label: 'oficina' },
  { value: 'marketing', label: 'marketing' },
  { value: 'comida', label: 'comida' },
  { value: 'salud', label: 'salud' },
  { value: 'viajes', label: 'viajes' },
  { value: 'renta', label: 'renta' },
  { value: 'servicio', label: 'servicio' },
  { value: 'transporte', label: 'transporte' },
  { value: 'suscripciones', label: 'suscripciones' },
  { value: 'otros', label: 'otros' }
]

export interface Expense {
  id: number
  user_id: number
  category: string
  note?: string
  date: Date
  amount: number
  name: string
  receipt_id?: number
  receipt?: ZReceipt
  image?: string
  pdf?: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}

export interface ExpenseStatus {
  receipt: InvoiceStatus
  expense: Expense
  user: User
  tax_information: TaxInformation
}

export enum DownloadExpensesBulkType {
  CSV = 'CSV',
  ZIP_PDF_XML = 'ZIP_PDF_XML'
}

export enum DownloadExpenseType {
  PDF = 'PDF',
  XML = 'XML'
}

export enum ExpensesFilterBy {
  DATE = 'DATE',
  CREATED_AT = 'CREATED_AT'
}

export const ExpensesFilterByOptions = [
  {
    label: 'Fecha de envío',
    value: ExpensesFilterBy.CREATED_AT
  },
  {
    label: 'Fecha de gasto',
    value: ExpensesFilterBy.DATE
  }
]

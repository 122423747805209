import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../../ts/interfaces/interfaces'
import { Payment, PaymentType } from '../../ts/interfaces/request'
import { createSelector } from 'reselect'
import { RootState } from '../index'

const selectSubscription = (state: RootState) => state.subscription
const selectInvoices = (state: RootState) => state.invoices

export const selectSubscriptionActive = createSelector([selectSubscription, selectInvoices], (subscription, invoices): boolean => {
  const { payment } = subscription

  return payment && payment.type !== PaymentType.FREE_TRIAL && invoices.active ? true : false
})

interface InitialState {
  products: Array<Product>
  payment: Payment | null
}

const initialState: InitialState = {
  products: [],
  payment: null
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<Partial<InitialState>>) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer

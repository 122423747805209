'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { routerObj } from '../components/routing/Router'
import { Flex, Icon, useDisclosure, useBoolean } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { Title } from '../components/fragments/Title'
import { useNavigate } from 'react-router-dom'
import { Button } from '../components/fragments/Button'
import { UserService } from '../services/User'
import { useAppSelector } from '../store/hooks'
import { User, ProductType } from '../ts/interfaces/interfaces'
import { ConfirmDialog } from '../components/fragments/ConfirmDialog'
import { TableData, UserTable } from '../components/ui/UserTable'
import { CellContext } from '@tanstack/react-table'
import { UsersFilter, IUsersFilterRef } from '../components/ui/filters/UsersFilter'
import { GetAllUsersParams } from '../ts/interfaces/request'
import { useUserUrlParamsFilter } from '../hooks/useUserUrlParamsFilter'

const UsersDashboardPage = () => {
  const surpassedLimitModal = useDisclosure()
  const deleteUserModal = useDisclosure()
  const subscription = useAppSelector((state) => state.subscription)
  const users = useAppSelector((state) => state.organization.users)
  const totalEnabledUsers = React.useMemo(() => {
    return users.filter(({ deleted_at }) => (!deleted_at ? true : false))
  }, [users])
  const navigate = useNavigate()
  const [targetDeleteUser, setTargetDeleteUser] = React.useState<User['id'] | null>(null)
  const [isLoading, toggleIsLoading] = useBoolean(false)
  const [userData, setUserData] = React.useState<{
    data: User[]
    total: number
  }>({
    data: [],
    total: 0
  })

  const { filterBy, page, page_size, setFilterBy, setPage, setPageSize } = useUserUrlParamsFilter({})

  const userFilterRef = React.useRef<IUsersFilterRef>(null)
  const userSeatsLimit: number = React.useMemo(() => {
    const seatProduct = subscription.products.find(({ type }) => type == ProductType.seat)

    return seatProduct ? (seatProduct.limit?.max as number) : 1
  }, [subscription])

  const retrieveData = async (params: Partial<GetAllUsersParams>) => {
    try {
      if (!params?.filter_by) setFilterBy('')
      toggleIsLoading.on()
      const { data, total } = await UserService({ useLoader: false }).getAllUsers({
        ...params,
        page,
        page_size
      })
      setUserData(() => ({
        data: [...data],
        total: total
      }))
    } catch (error) {
      console.error(error)
    } finally {
      toggleIsLoading.off()
    }
  }

  const handleDeleteUser = React.useCallback(async () => {
    await UserService({ useLoader: true }).deleteUser(targetDeleteUser as User['id'])
    setTargetDeleteUser(null)
    await retrieveData({ filter_by: null, setStateResult: true })
  }, [targetDeleteUser, setTargetDeleteUser])

  const handleEnableUser = React.useCallback(
    async (userId: User['id']) => {
      if (totalEnabledUsers.length >= userSeatsLimit) {
        return surpassedLimitModal.onOpen()
      } else {
        await UserService({ useLoader: true }).enableUser(userId)
        await retrieveData({ filter_by: null, setStateResult: true })
      }
    },
    [userSeatsLimit, surpassedLimitModal, totalEnabledUsers]
  )

  const handleAddUser = React.useCallback(() => {
    if (totalEnabledUsers.length >= userSeatsLimit) {
      return surpassedLimitModal.onOpen()
    }

    navigate(routerObj.AddUser.path)
  }, [subscription, users, surpassedLimitModal])

  const handleDeleteIconButton = (data: CellContext<TableData, string | undefined>) => {
    if (data.row.original.deleted_at) {
      handleEnableUser(data.row.original.id)
    } else {
      setTargetDeleteUser(data.row.original.id)
      deleteUserModal.onOpen()
    }
  }

  React.useEffect(() => {
    retrieveData({ filter_by: null, setStateResult: true })
  }, [])

  React.useEffect(() => {
    retrieveData({ filter_by: filterBy, setStateResult: false })
  }, [page, page_size])

  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row'
        }}
        mb='1rem'
        alignItems='center'
        justifyContent={{
          base: '',
          md: 'space-between'
        }}
      >
        <Title>Usuarios</Title>
        <Button
          width='fit-content'
          onClick={handleAddUser}
          gap='0.4rem'
        >
          Agregar usuario{' '}
          <Icon
            aria-label='agregar grupo'
            as={FaPlus}
          />
        </Button>
      </Flex>

      <UsersFilter
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        setPage={setPage}
        ref={userFilterRef}
        retrieveData={retrieveData}
        isLoading={isLoading}
      />

      <UserTable
        page={page}
        page_size={page_size}
        total={userData.total}
        data={userData.data}
        setPage={setPage}
        setPageSize={setPageSize}
        isLoading={isLoading}
        handleDeleteIconButton={handleDeleteIconButton}
      />

      <ConfirmDialog
        isOpen={surpassedLimitModal.isOpen}
        onClose={surpassedLimitModal.onClose}
        title={`Tu plan actual solo permite ${userSeatsLimit > 1 ? `${userSeatsLimit} usuarios` : '1 usuario'} de WhatsApp. Si quieres contratar un usuario adicional por favor contáctanos.`}
        confirmMethod={() => {
          window.open(process.env.REACT_APP_HELP_URL, '_blank')
        }}
        cancelType='red'
        confirmType='blue'
        confirmMessage='Contactar'
        cancelMessage='Cancelar'
      />

      <ConfirmDialog
        isOpen={deleteUserModal.isOpen}
        onClose={deleteUserModal.onClose}
        title={'¿Quieres deshabilitar a este usuario?'}
        message='Al deshabilitar un usuario, este deja de poder usar el servicio de facturación. Puedes volver a habilitarlo en cualquier momento.'
        confirmMethod={handleDeleteUser}
        cancelType='blue'
        confirmType='red'
        confirmMessage='Deshabilitar'
        cancelMessage='Cancelar'
      />
    </PageLayout>
  )
}

export { UsersDashboardPage }

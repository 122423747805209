import React from 'react'
import { AnalyticType } from '../ts/interfaces/analytics'
import { AnalyticsService } from '../services/Analytics'
import { DateValue } from '../components/fields/Date/DateRangePicker'
import { isValidDateRange, isEqualDateRange } from '../utils/date'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { GetAnalyticsResponse } from '../ts/interfaces/request'

interface UseAnalyticsOutput {
  isLoading: boolean
  data: GetAnalyticsResponse['data']
}

const useAnalytics = ({ type, dateRange, filterBy, signal }: { type: AnalyticType; dateRange: DateValue; filterBy: ExpensesFilterBy; signal?: AbortSignal }): UseAnalyticsOutput => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<GetAnalyticsResponse['data']>([])
  const [dateRangeReference, setDateRangeReference] = React.useState<DateValue | null>(null)
  const [filterByReference, setFilterByReference] = React.useState<ExpensesFilterBy | null>(null)

  React.useEffect(() => {
    const abortController = new AbortController()
    if (!isValidDateRange(dateRange) || (isEqualDateRange(dateRangeReference, dateRange) && filterByReference === filterBy)) {
      return
    }

    ;(async () => {
      try {
        setIsLoading(true)
        const responseData = await AnalyticsService().getAnalytics({
          type,
          start_date: dateRange[0],
          end_date: dateRange[1],
          filter_by: filterBy,
          signal: abortController.signal
        })
        if (!responseData) return
        setData(() => {
          setIsLoading(false)
          return responseData
        })
        setDateRangeReference(() => dateRange)
        setFilterByReference(() => filterBy)
      } catch (error) {
        if (signal?.aborted) {
          // canceled request
        }
      }
    })()

    return () => {
      abortController.abort()
    }
  }, [type, dateRange, filterBy])

  return {
    isLoading,
    data
  }
}

export { useAnalytics }

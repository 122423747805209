import React from 'react'
import moment from 'moment'
import { DateValue } from '../components/fields/Date/DateRangePicker'
import { useSearchParams } from 'react-router-dom'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { isoToDate, isValidDateRange, dateToUrlIso } from '../utils/date'
import _ from 'lodash'
import { PAGINATION_CONFIG } from '../components/fragments/Table'
import { SortType } from '../ts/interfaces/hooks'
import { ReceiptStatusTranslator, ReceiptStatus } from '../ts/interfaces/invoices'
// NOTE: this filter applies the logic of get_expenses handler of the zumma-service api

interface IUseUrlParamFilterProps {
  useFilterBy?: boolean
  initialDateRange?: DateValue
  initialFilterBy?: ExpensesFilterBy
  usePagination?: boolean
  initialPage?: number
  initialPageSize?: number
}

const getDateInitialValue = (searchParams: URLSearchParams, initialValue?: DateValue): DateValue | undefined => {
  let date1: null | Date = null
  let date2: null | Date = null
  if (searchParams.get('start_date') !== null && searchParams.get('start_date') !== '') date1 = isoToDate(searchParams.get('start_date') as string)
  if (searchParams.get('end_date') !== null && searchParams.get('end_date') !== '')
    date2 = moment(isoToDate(searchParams.get('end_date') as string))
      .endOf('day')
      .toDate()

  const urlParamsDate: DateValue = [date1, date2]

  if (isValidDateRange(urlParamsDate)) return urlParamsDate
  return initialValue
}

const getInitialUserId = (searchParams: URLSearchParams): number | null => {
  const user_id = searchParams.get('user_id')
  if (user_id !== null && user_id !== '') return parseInt(user_id)
  return null
}

const getInitialTaxInformationId = (searchParams: URLSearchParams): string | null => {
  const tax_information_id = searchParams.get('tax_information_id')
  if (tax_information_id !== null && tax_information_id !== '') return tax_information_id
  return null
}

const getInitialStatus = (searchParams: URLSearchParams): ReceiptStatus | null => {
  const status = searchParams.get('status')
  if (status !== null && status !== '' && Object.values(ReceiptStatus).includes(status.toUpperCase() as ReceiptStatus)) {
    return status as ReceiptStatus
  }
  return null
}
const getInitialPage = (searchParams: URLSearchParams, initialValue: number): number => {
  const page = searchParams.get('page')
  if (page !== null && page !== '') return parseInt(page)
  return initialValue
}

const getInitialPageSize = (searchParams: URLSearchParams, initialValue: number): number => {
  const page_size = searchParams.get('page_size')
  if (page_size !== null && page_size !== '') return parseInt(page_size)
  return initialValue
}

const getInitialSortBy = (searchParams: URLSearchParams): string => {
  const sort_by = searchParams.get('sort_by')
  if (sort_by !== null && sort_by !== '') return sort_by
  return ''
}

const getInitialSortType = (searchParams: URLSearchParams): SortType | undefined => {
  const sort_type = searchParams.get('sort_type')
  if (sort_type !== null && sort_type !== '') return sort_type as SortType
}

const getFilterBy = (searchParams: URLSearchParams): ExpensesFilterBy | undefined => {
  const filter_by = searchParams.get('filter_by')
  if (filter_by !== null && filter_by !== '') return filter_by as ExpensesFilterBy
}

const useUrlParamFilter = ({ useFilterBy = true, initialDateRange, initialFilterBy = ExpensesFilterBy.CREATED_AT, usePagination = false, initialPage = PAGINATION_CONFIG.initialConfig.page, initialPageSize = PAGINATION_CONFIG.initialConfig.pageSize }: IUseUrlParamFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterBy, setFilterBy] = React.useState<ExpensesFilterBy>(getFilterBy(searchParams) || initialFilterBy)
  const [dateValue, setDateValue] = React.useState<DateValue | null | undefined>(getDateInitialValue(searchParams, initialDateRange))
  const [taxInformationId, setTaxInformationId] = React.useState<string | undefined | null>(getInitialTaxInformationId(searchParams))
  const [status, setStatus] = React.useState<ReceiptStatus | null | undefined>(getInitialStatus(searchParams))
  const [userId, setUserId] = React.useState<number | undefined | null>(getInitialUserId(searchParams))
  const [page, setPage] = React.useState<number>(getInitialPage(searchParams, initialPage))
  const [page_size, setPageSize] = React.useState<number>(getInitialPageSize(searchParams, initialPageSize))

  const [sort_by, setSortBy] = React.useState<string>(getInitialSortBy(searchParams))
  const [sort_type, setSortType] = React.useState<SortType | undefined>(getInitialSortType(searchParams))

  React.useEffect(() => {
    let date1: null | Date = null
    let date2: null | Date = null
    if (searchParams.get('start_date') !== null && searchParams.get('start_date') !== '') date1 = isoToDate(searchParams.get('start_date') as string)
    if (searchParams.get('end_date') !== null && searchParams.get('end_date') !== '')
      date2 = moment(isoToDate(searchParams.get('end_date') as string))
        .endOf('day')
        .toDate()

    const urlParamsDate: DateValue = [date1, date2]

    if (isValidDateRange(urlParamsDate)) {
      setDateValue(() => urlParamsDate)
    } else if (isValidDateRange(initialDateRange)) {
      searchParams.set('start_date', dateToUrlIso(initialDateRange[0] as Date))
      searchParams.set('end_date', dateToUrlIso(initialDateRange[1] as Date))
      setDateValue(() => initialDateRange)
    } else {
      const newDateRange: DateValue = [moment().startOf('month').toDate(), moment().toDate()]
      setDateValue(() => newDateRange)
      searchParams.set('start_date', dateToUrlIso(newDateRange[0] as Date))
      searchParams.set('end_date', dateToUrlIso(newDateRange[1] as Date))
    }

    if (useFilterBy) searchParams.set('filter_by', filterBy)
    if (usePagination) {
      searchParams.set('page', (page as number).toString())
      searchParams.set('page_size', (page_size as number).toString())
    }

    if (status) searchParams.set('status', status)
    setSearchParams(searchParams)
  }, [])

  return {
    dateValue: dateValue || [null, null],
    filterBy: filterBy,
    setDateValue: (value: DateValue) => {
      if (value[0]) searchParams.set('start_date', dateToUrlIso(value[0]))
      if (value[1]) searchParams.set('end_date', dateToUrlIso(value[1]))

      if (usePagination) {
        searchParams.set('page', PAGINATION_CONFIG.initialConfig.page.toString())
        setPage(() => PAGINATION_CONFIG.initialConfig.page)
      }

      setSearchParams(searchParams)
      setDateValue(value)
    },
    setFilterBy: (value: ExpensesFilterBy) => {
      searchParams.set('filter_by', value)

      if (usePagination) {
        searchParams.set('page', PAGINATION_CONFIG.initialConfig.page.toString())
        setPage(() => PAGINATION_CONFIG.initialConfig.page)
      }
      setSearchParams(searchParams)
      setFilterBy(value)
    },
    setUserId: (value?: number | null) => {
      if (value) searchParams.set('user_id', value.toString())
      else searchParams.delete('user_id')

      if (usePagination) {
        searchParams.set('page', PAGINATION_CONFIG.initialConfig.page.toString())
        setPage(() => PAGINATION_CONFIG.initialConfig.page)
      }
      setSearchParams(searchParams)
      setUserId(value)
    },
    userId,
    taxInformationId,
    setTaxInformationId: (value?: string | null) => {
      if (value) searchParams.set('tax_information_id', value)
      else searchParams.delete('tax_information_id')

      if (usePagination) {
        searchParams.set('page', PAGINATION_CONFIG.initialConfig.page.toString())
        setPage(() => PAGINATION_CONFIG.initialConfig.page)
      }
      setSearchParams(searchParams)
      setTaxInformationId(value)
    },
    page,
    page_size,
    setPage: (value: number) => {
      searchParams.set('page', value.toString())
      setSearchParams(searchParams)
      setPage(() => value)
    },
    setPageSize: (value: number) => {
      searchParams.set('page_size', value.toString())
      setSearchParams(searchParams)
      setPageSize(() => value)
    },
    sort_by,
    setSortBy: (value: string) => {
      searchParams.set('sort_by', value)
      setSearchParams(searchParams)
      setSortBy(() => value)
    },
    sort_type,
    setSortType: (value: SortType) => {
      searchParams.set('sort_type', value)
      setSearchParams(searchParams)
      setSortType(() => value)
    },
    status,
    setStatus: (value?: ReceiptStatus | null | undefined) => {
      searchParams.set('status', `${value}`)
      setSearchParams(searchParams)
      setStatus(() => value)
    }
  }
}

export { useUrlParamFilter }

import { Badge, BoxProps, Flex, Progress, Skeleton, Text, useBoolean, useToast } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded, fontSize, containerCardStyles } from '../../utils/ui'
import { useEffect } from 'react'
import { InvoicesService } from '../../services/Invoices'
import { GetInvoicesStatusResponse } from '../../ts/interfaces/request'

interface IServiceUseProgressProps extends BoxProps {}

const ServiceUseProgress = React.memo(({ ...props }: IServiceUseProgressProps) => {
  const toast = useToast()
  const [isLoading, toggleIsLoading] = useBoolean(false)
  const [invoicesData, setInvoicesData] = React.useState<GetInvoicesStatusResponse['data']>({ count: 0, limit: 0, active: false, total: 0 })
  const fetchDataRef = React.useRef<AbortController | null>(null)

  const retrieveData = async () => {
    toggleIsLoading.on()
    const controller = new AbortController()
    const signal = controller.signal

    fetchDataRef.current?.abort()
    fetchDataRef.current = controller

    try {
      const response = await InvoicesService().getInvoicesStatus({ fetch_stats: true, signal })
      if (response) setInvoicesData(response)
    } catch (error) {
      if (signal?.aborted) {
        // canceled request
      } else {
        toast({
          title: 'Error al obtener la información de las facturas',
          description: 'Por favor, intente nuevamente más tarde.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    } finally {
      toggleIsLoading.off()
    }
  }

  useEffect(() => {
    retrieveData()
    return () => {
      fetchDataRef.current?.abort()
    }
  }, [])

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        id='ServiceUseProgress'
        bg={colors.white}
        borderColor={colors.border}
        borderWidth={1}
        rounded={rounded.container}
        padding='1rem'
        direction='column'
        justifyContent='center'
        {...containerCardStyles}
        {...props}
      >
        <Flex
          w='100%'
          flexWrap='wrap'
          flexDir={{
            base: 'row',
            lg: 'row'
          }}
          justifyContent={{
            base: 'space-between',
            lg: 'space-between'
          }}
          alignItems={{
            base: 'start',
            lg: 'center'
          }}
        >
          <Flex
            flexDir='column'
            alignItems='start'
          >
            <Badge
              fontSize={{
                base: '1.6rem',
                md: "'1.6rem'"
              }}
              fontWeight={600}
              colorScheme='purple'
            >
              {invoicesData.count}
            </Badge>
            <Text
              as='span'
              fontWeight={500}
              fontSize={fontSize.title}
            >
              Tickets enviados
            </Text>
          </Flex>
          <Flex
            flexDir='column'
            alignItems='end'
          >
            <Badge
              fontSize={{
                base: '1.6rem',
                md: "'1.6rem'"
              }}
              fontWeight={600}
              colorScheme='purple'
            >
              {invoicesData.limit}
            </Badge>
            <Text
              as='span'
              fontWeight={500}
              fontSize={fontSize.title}
            >
              Límite de tickets
            </Text>
          </Flex>
        </Flex>
        <Progress
          colorScheme='blue'
          rounded='0.15rem'
          hasStripe
          size='md'
          value={(invoicesData.count * 100) / invoicesData.limit}
          my='1rem'
        />
      </Flex>
    </Skeleton>
  )
})

export { ServiceUseProgress }

'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { UserForm } from '../components/ui/UserForm'
import { useToast, Flex } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { Buffer } from 'buffer'
import { UserService } from '../services/User'
import { UpdateUserForm, UserRole } from '../ts/interfaces/interfaces'
import { Title } from '../components/fragments/Title'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { IconButton } from '../components/fragments/Button'

const UpdateUserPage = () => {
  const userId = useParams().id
  const decodedUserId = React.useMemo(() => Buffer.from(userId || '', 'base64').toString('ascii'), [userId])
  const [userData, setUserData] = React.useState<UpdateUserForm>()
  const [userRole, setUserRole] = React.useState<UserRole>()
  const navigate = useNavigate()
  const toast = useToast()

  const init = React.useCallback(async () => {
    if (!decodedUserId) return navigate(-1)
    try {
      const data = await UserService().getUser(parseInt(decodedUserId))
      delete data.created_at
      delete data.updated_at
      setUserRole(data.role)
      setUserData({
        id: data.id,
        email: data.email,
        phone_number: data.phone_numbers[0],
        name: data.name,
        role: data.role,
        tax_information: data.tax_information.map(({ id }) => id)
      })
    } catch (error) {
      navigate(-1)
      toast({
        title: 'Hubo un problema.',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
    }
  }, [userId, UserService])

  React.useEffect(() => {
    init()
  }, [userId])

  return (
    <PageLayout>
      <Flex
        mt='4rem'
        mb='2rem'
        px='2rem'
        gap='1rem'
        justifyContent='center'
        alignItems='center'
      >
        <IconButton
          onClick={() => navigate(-1)}
          aria-label='go back button'
          icon={<FaArrowAltCircleLeft />}
        />
        <Title>Actualizar Usuario</Title>
      </Flex>
      {userData ? (
        <UserForm
          initialForm={userData}
          userRole={userRole}
        />
      ) : null}
    </PageLayout>
  )
}

export { UpdateUserPage }

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, BoxProps, SimpleGrid, Text, useBoolean, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { containerCardStyles } from '../../utils/ui'
import { routerObj } from '../routing/Router'
import { useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { InvoicesService } from '../../services/Invoices'
import { Buffer } from 'buffer'
import { HiIdentification } from 'react-icons/hi2'
import { SendInvoiceButton } from './SendInvoiceButton'
import { PricingBanner } from './PricingBanner'
import { Link } from '../fragments/Link'
import { Button } from '../fragments/Button'
import { PaymentType } from '../../ts/interfaces/request'
import { FaAngleRight, FaPencilAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setIsFetching } from '../../store/ui/slice'
import { SubscriptionService } from '../../services/Subscription'
interface IOnboardingProgressProps extends BoxProps {}

const OnboardingProgress = React.memo(({ ...props }: IOnboardingProgressProps) => {
  const invoices = useAppSelector((state) => state.invoices)
  const subscription = useAppSelector((state) => state.subscription)
  const ui = useAppSelector((state) => state.ui)
  const dispatch = useDispatch()
  const [disableIntro, toggleDisableIntro] = useBoolean()
  const navigate = useNavigate()

  const [accordionIndex, setAccordionIndex] = React.useState<number>(0)
  const onboardingProgressValue: number = React.useMemo(() => {
    const hasSubscription = !subscription.payment ? 0 : subscription.payment.type !== PaymentType.FREE_TRIAL ? 100 : 0
    const hasTaxInformation = invoices.taxInformation.length ? 33.33 : 0
    const sentFirstInvoice = invoices.total ? 33.33 : 0
    return hasTaxInformation + hasSubscription + sentFirstInvoice
  }, [invoices, subscription])

  const retrieveData = async () => {
    dispatch(setIsFetching(true))
    await SubscriptionService({ useLoader: false }).getSubscriptionStatus()
    await InvoicesService({ useLoader: false }).getInvoicesStatus({ fetch_stats: true })
    await InvoicesService({ useLoader: false }).getAllTaxInformation()
    dispatch(setIsFetching(false))
  }

  React.useEffect(() => {
    retrieveData()
  }, [])

  if (onboardingProgressValue >= 99 || ui.isFetching) return null

  return (
    <Box
      id='OnboardingProgress'
      {...containerCardStyles}
      padding={0}
      paddingBottom='1rem'
      {...props}
    >
      <Text
        as='h3'
        fontSize='1.2rem'
        display='flex'
        alignItems='center'
        columnGap='1rem'
        fontWeight={500}
        padding='1rem'
        borderWidth='0'
        borderBottom='1px'
        borderColor={colors.tertiary[1]}
      >
        <HiIdentification /> Completa tu usuario y comienza a usar el servicio de facturación de gastos:
      </Text>

      <Box padding='1rem'>
        <Accordion
          allowMultiple
          index={accordionIndex}
          allowToggle
        >
          <AccordionItem isDisabled={disableIntro || invoices.taxInformation.length > 0}>
            <AccordionButton
              onClick={() => setAccordionIndex(0)}
              textAlign='start'
            >
              <Text>1. Ve todo lo que puedes hacer con Zumma.</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <SimpleGrid
                columnGap='1rem'
                columns={{ md: 2 }}
              >
                <Box
                  mb={{
                    base: '2rem',
                    md: 0
                  }}
                >
                  <Text mb='1rem'>¡Bienvenido al servicio de facturación automática de gastos de Zumma! Ahora tú y tu equipo podrán facturar sus gastos a través de WhatsApp ahorrando tiempo y disminuyendo la cantidad de gastos no deducibles de tu negocio o empresa.</Text>
                  <Text mb='2rem'>
                    Ve el siguiente video para entender cómo utilizar el servicio de facturación de gastos y cómo aprovecharlo al máximo. También, recuerda que puedes ponerte en contacto con nuestro equipo de <Link href={process.env.REACT_APP_HELP_URL as string}>Soporte por WhatsApp en (55) 4808 1624</Link>.
                  </Text>

                  <Button
                    id='OnboardingProgressContinueButton'
                    onClick={() => {
                      if (invoices.taxInformation.length > 0) setAccordionIndex(2)
                      else if (invoices.total > 0) setAccordionIndex(3)
                      else setAccordionIndex(1)

                      toggleDisableIntro.on()
                    }}
                    rightIcon={<FaAngleRight />}
                  >
                    Continuar
                  </Button>
                </Box>

                <iframe
                  width='100%'
                  height='360'
                  src='https://www.youtube.com/embed/0U2mHw9bDYU?si=DUWUpAKR7zvtY__d'
                  title='YouTube video player'
                  //@ts-ignore
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  referrerPolicy='strict-origin-when-cross-origin'
                  allowFullScreen
                ></iframe>
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            onClick={() => setAccordionIndex(1)}
            id='OnboardingProgressStep2Button'
            isDisabled={invoices.taxInformation.length > 0}
          >
            <AccordionButton textAlign='start'>
              <Text>2. Registra tu información fiscal.</Text>
            </AccordionButton>

            <AccordionPanel>
              <Text mb='2rem'>Para poder empezar a facturar tus gastos, necesitamos que ingreses la información fiscal de la empresa o persona a la que estarás facturando los gastos.</Text>
              <Button
                id='OnboardingProgressAddTaxInformationButton'
                onClick={() => {
                  if (invoices.taxInformation.length > 0) return navigate(`${routerObj.updateTaxInformation.cleanParamsPath}/${Buffer.from(invoices.taxInformation[0].id.toString()).toString('base64')}`)

                  const urlSearchParams = new URLSearchParams({ first: 'true' })
                  navigate(routerObj.createTaxInformation.path + '?' + urlSearchParams)
                }}
                rightIcon={<FaPencilAlt />}
              >
                Ingresa tu información fiscal
              </Button>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            onClick={() => setAccordionIndex(2)}
            id='OnboardingProgressStep3Button'
            isDisabled={invoices.total > 0}
          >
            <AccordionButton textAlign='start'>
              <Text>
                3. Prueba el servicio de facturación enviando <b>un ticket a facturar sin costo (tienes 7 días para usarlo).</b>{' '}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <SimpleGrid
                columns={{
                  base: 1,
                  lg: 2
                }}
                placeItems='center'
              >
                <p>
                  Para facturar tu primer ticket asegúrate de que tu información fiscal sea correcta y ve a WhatsApp dando click aquí o escaneando el siguiente QR. Si el número con el que te registraste, no es el número de WhatsApp con el que estarás facturando tus tickets,{' '}
                  <Link
                    target='_self'
                    href={routerObj.usersDashboard.path}
                  >
                    {' '}
                    puedes cambiarlo aquí.
                  </Link>
                </p>
                <SendInvoiceButton
                  mt={{
                    base: '2rem',
                    lg: 0
                  }}
                  width={{
                    lg: '70%'
                  }}
                />
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            onClick={() => setAccordionIndex(3)}
            id='OnboardingProgressStep4Button'
            isDisabled={invoices.active && subscription?.payment && subscription?.payment?.type !== PaymentType.FREE_TRIAL ? true : false}
          >
            <AccordionButton textAlign='start'>
              <Text>4. Paga tu suscripción para continuar facturando tus gastos aquí.</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <PricingBanner />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
})

export { OnboardingProgress }
